import ProdConfig from './config.prod'
import DevConfig from './config.dev'

let ExtraConfig = ProdConfig

const __DEV__ = process.env.NODE_ENV === 'development' && false

if (__DEV__) {
  ExtraConfig = DevConfig
}

const Config = { ...ExtraConfig }

export default Config
