import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-form-container" }

import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/store'
import LoginButtonGrad from '@/components/button/LoginButtonGrad.vue'
import LoginInput from '@/components/input/LoginInput.vue'
import PACombo from './PACombo.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginForm',
  emits: ['onNavigateRegister'],
  setup(__props, { emit: __emit }) {

const router = useRouter()

const authStore = useAuthStore()

const { email, password } = storeToRefs(authStore)
const { setEmail, setPassword } = authStore

const emit = __emit

const cssInputContainer = {
  width: '100%',
  margin: '0.48rem 0'
}

async function onLogin () {
  const status = await authStore.login()
  if (status !== 'ok') {
    return
  }
  router.push('/dashboard')
}

function onNavigateRegister () {
  emit('onNavigateRegister')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(LoginInput, {
      name: "email",
      label: "Email",
      type: "email",
      placeholder: "Email",
      "model-value": _unref(email),
      "onUpdate:modelValue": _unref(setEmail),
      onKeydown: _withKeys(onLogin, ["enter"]),
      "container-style": cssInputContainer
    }, null, 8, ["model-value", "onUpdate:modelValue"]),
    _createVNode(LoginInput, {
      name: "password",
      label: "Password",
      type: "password",
      placeholder: "Password",
      "model-value": _unref(password),
      "onUpdate:modelValue": _unref(setPassword),
      onKeydown: _withKeys(onLogin, ["enter"]),
      "container-style": cssInputContainer
    }, null, 8, ["model-value", "onUpdate:modelValue"]),
    _createVNode(LoginButtonGrad, {
      text: "Login",
      onClick: onLogin
    }),
    _createVNode(PACombo, {
      onOnClick: onNavigateRegister,
      text: "New to Keypin?",
      "link-text": "Create an Account"
    })
  ]))
}
}

})