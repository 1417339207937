import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

import { api } from '@/api'

export const useAuthStore = defineStore('auth', () => {
  const email = ref('')
  const password = ref('')

  const isAuthenticated = computed(() => !!api.token)
  const isEmailValid = computed(() => email.value.length > 0)

  function setEmail (value: string) {
    email.value = value
  }
  function setPassword (value: string) {
    password.value = value
  }
  async function login (): Promise<string> {
    const response = await api.postLoginEmail(email.value)
    if (response.kind !== 'ok') {
      console.log(`Error logging in: ${JSON.stringify(response)}`, [])
      return response.kind + ': ' + response
    }

    const keypin = response.keypin

    const loginResponse = await api.postLogin(keypin, password.value)
    if (loginResponse.kind !== 'ok') {
      console.log(`Error logging in: ${JSON.stringify(loginResponse)}`, [])
      return loginResponse.kind
    }

    email.value = ''
    password.value = ''
    return loginResponse.kind
  }
  function logout () {
    api.getLogout()
    email.value = ''
    password.value = ''
  }

  return {
    email,
    password,
    isAuthenticated,
    isEmailValid,
    setEmail,
    setPassword,
    login,
    logout
  }
})
