<script setup lang="ts">
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/store'
import LoginButtonGrad from '@/components/button/LoginButtonGrad.vue'
import LoginInput from '@/components/input/LoginInput.vue'
import PACombo from './PACombo.vue'

const router = useRouter()

const authStore = useAuthStore()

const { email, password } = storeToRefs(authStore)
const { setEmail, setPassword } = authStore

const emit = defineEmits(['onNavigateRegister'])

const cssInputContainer = {
  width: '100%',
  margin: '0.48rem 0'
}

async function onLogin () {
  const status = await authStore.login()
  if (status !== 'ok') {
    return
  }
  router.push('/dashboard')
}

function onNavigateRegister () {
  emit('onNavigateRegister')
}
</script>

<template>
  <div class="login-form-container">
    <LoginInput
       name="email"
       label="Email"
       type="email"
       placeholder="Email"
       :model-value="email"
       @update:model-value="setEmail"
       @keydown.enter="onLogin"
       :container-style="cssInputContainer"
        />
    <LoginInput
        name="password"
        label="Password"
        type="password"
        placeholder="Password"
        :model-value="password"
        @update:model-value="setPassword"
        @keydown.enter="onLogin"
        :container-style="cssInputContainer"
    />
    <LoginButtonGrad text="Login" @click="onLogin"/>
    <PACombo
      @on-click="onNavigateRegister"
      text="New to Keypin?"
      link-text="Create an Account"
    />
   </div>
 </template>

<style scoped lang="scss">
  div.login-form-container{
    flex-direction: column;
    align-items: center;
    width: 60%;
  }
</style>
